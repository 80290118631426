import React, { useState, useEffect, useContext } from 'react';
import { Grid, IconButton, Checkbox, FormControlLabel } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { postTruck, getTrucks, putTruck, deleteTruck, getSpecialties } from '../../_actions/Manage.actions';
import { useWindowSize } from './ManageController';
import { updateUserActivity } from '../../_actions/Global.actions';
import { EditDialogue } from './EditDialogue';
import { DeleteDialogue } from './DeleteDialogue';
import { updateAllServiceSide } from '../../_actions/Manage.actions';
import { useTranslation } from 'react-i18next';
import { ManagementSpecialtyDialog } from './SpecialtyDialogs';
import { GlobalROContext } from '../common';

const TruckTable = (props) => {
    const { showContainerDelivery } = useContext(GlobalROContext);
    const { t } = useTranslation();

    const title = t('RoutePlans.Truck.Title');
    const defaultName = 'Default';

    const { workspace, setRefreshRoutePlanAndRouteSet, setRefreshRouteSet } = props;

    const size = useWindowSize();
    let truckContainerHeight = (size.height - 195) / 2;
    let truckGridHeight = truckContainerHeight - 48;

    const truckCustomSort = (a, b, c) => {
        if (b && b.original && b.original['TruckName'] === defaultName) {
            return 1;
        } else if (a && a.original && a.original['TruckName'] === defaultName) {
            return -1;
        } else {
            if (isNaN(a.original[c]) || isNaN(b.original[c])) {
                return a.original[c].localeCompare(b.original[c], 'en', { numeric: true });
            } else {
                return a.original[c] - b.original[c];
            }
        }
    };

    let columns = [
        {
            header: t('RoutePlans.Truck.Columns.Truck'),
            Header: ({ column }) => (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <span
                        className='TR_TRUCK'
                        style={{ fontSize: '22px' }}
                    ></span>
                    {column.columnDef.header}
                </div>
            ),
            accessorKey: 'TruckName',
            size: 50,
            sortingFn: (a, b, c) => truckCustomSort(a, b, c),
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '12px' } },
            muiTableBodyCellProps: { sx: { fontSize: '12px', borderBottom: 'none' } },
            Cell: ({ cell, row }) => {
                if (cell.getValue() === defaultName) {
                    return (
                        <b>
                            <i>{cell.getValue()}</i>
                        </b>
                    );
                } else {
                    return cell.getValue();
                }
            },
        },
        {
            header: t('RoutePlans.Truck.Columns.Capacity'),
            Header: ({ column }) => (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <span
                        className='TR_TRUCK_CAPACITY'
                        style={{ fontSize: '22px' }}
                    ></span>
                    {column.columnDef.header}
                </div>
            ),
            accessorKey: 'VehicleCapacity',
            size: 50,
            sortingFn: (a, b, c) => truckCustomSort(a, b, c),
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '12px' } },
            muiTableBodyCellProps: { align: 'center', sx: { fontSize: '12px', borderBottom: 'none' } },
            Cell: ({ cell, row }) => {
                if (row.original.TruckName === defaultName) {
                    return (
                        <b>
                            <i>{cell.getValue()}</i>
                        </b>
                    );
                } else {
                    return cell.getValue();
                }
            },
        },
        {
            header: t('RoutePlans.Truck.Columns.Daily'),
            Header: ({ column }) => (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <span
                        className='TR_DAILY'
                        style={{ fontSize: '22px' }}
                    ></span>
                    {column.columnDef.header}
                </div>
            ),
            accessorKey: 'CostFixed',
            size: 50,
            sortingFn: (a, b, c) => truckCustomSort(a, b, c),
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '12px' } },
            muiTableBodyCellProps: ({ cell, table }) => ({
                align: 'center',
                sx: { fontSize: '12px', borderBottom: 'none', padding: '0 0.5rem' },
            }),
            Cell: ({ cell, row }) => {
                if (row.original.TruckName === defaultName) {
                    if (cell.getValue() === 0) {
                        return (
                            <b>
                                <i>-</i>
                            </b>
                        );
                    } else {
                        return (
                            <b>
                                <i>${cell.getValue().toFixed(2)}</i>
                            </b>
                        );
                    }
                } else {
                    if (cell.getValue() === 0) {
                        return `-`;
                    } else {
                        return `$${cell.getValue().toFixed(2)}`;
                    }
                }
            },
        },
        {
            header: t('RoutePlans.Truck.Columns.Hourly'),
            Header: ({ column }) => (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <span
                        className='DR_HOUR'
                        style={{ fontSize: '22px' }}
                    ></span>
                    {column.columnDef.header}
                </div>
            ),
            accessorKey: 'CostPerHour',
            size: 50,
            sortingFn: (a, b, c) => truckCustomSort(a, b, c),
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '12px' } },
            muiTableBodyCellProps: ({ cell, table }) => ({
                align: 'center',
                sx: { fontSize: '12px', borderBottom: 'none', padding: '0 0.5rem' },
            }),
            Cell: ({ cell, row }) => {
                if (row.original.TruckName === defaultName) {
                    if (!cell.getValue() || cell.getValue() === 0) {
                        return (
                            <b>
                                <i>-</i>
                            </b>
                        );
                    } else if (cell.getValue()) {
                        return (
                            <b>
                                <i>${cell.getValue().toFixed(2)}</i>
                            </b>
                        );
                    }
                } else {
                    if (!cell.getValue() || cell.getValue() === 0) {
                        return `-`;
                    } else {
                        return `$${cell.getValue()?.toFixed(2)}`;
                    }
                }
            },
        },
        {
            header: t('RoutePlans.Truck.Columns.Distance'),
            Header: ({ column }) => (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <span
                        className='DR_DISTANCE'
                        style={{ fontSize: '22px' }}
                    ></span>
                    {column.columnDef.header}
                </div>
            ),
            accessorKey: 'CostVariable',
            size: 50,
            sortingFn: (a, b, c) => truckCustomSort(a, b, c),
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '12px' } },
            muiTableBodyCellProps: ({ cell, table }) => ({
                align: 'center',
                sx: { fontSize: '12px', borderBottom: 'none', padding: '0 0.5rem' },
            }),
            Cell: ({ cell, row }) => {
                if (row.original.TruckName === defaultName) {
                    if (cell.getValue() === 0) {
                        return (
                            <b>
                                <i>-</i>
                            </b>
                        );
                    } else {
                        return (
                            <b>
                                <i>${cell.getValue().toFixed(2)}</i>
                            </b>
                        );
                    }
                } else {
                    if (cell.getValue() === 0) {
                        return `-`;
                    } else {
                        return `$${cell.getValue().toFixed(2)}`;
                    }
                }
            },
        },
        {
            header: t('RoutePlans.Truck.Columns.ServiceSide'),
            Header: ({ column }) => (
                <>
                    <div
                        style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
                        title=''
                    >
                        <span
                            className='TR_SERVICE_SIDE'
                            style={{ fontSize: '22px' }}
                        ></span>
                        {column.columnDef.header}
                    </div>
                    <div
                        style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
                        title={t('RoutePlans.Truck.Labels.ServiceSide')}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isAllSameServiceSide(data)}
                                    disabled={props.siteMode === 'PLANNER'}
                                    onChange={handleAllServiceSideChange}
                                    size='small'
                                    sx={{
                                        '&.Mui-checked': {
                                            '&, & + .MuiFormControlLabel-label': {
                                                fontWeight: 'bold',
                                            },
                                        },
                                    }}
                                />
                            }
                            label={<span style={{ fontSize: '12px', borderBottom: 'none' }}>{t('RoutePlans.Truck.Columns.AllSame')}</span>}
                        />
                    </div>
                </>
            ),
            accessorKey: 'ServiceSide',
            size: 50,
            enableSorting: false,
            sortingFn: (a, b, c) => truckCustomSort(a, b, c),
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '12px' } },
            muiTableBodyCellProps: { align: 'center', sx: { fontSize: '12px', borderBottom: 'none' } },
            Cell: ({ cell, row }) => {
                return row.original.TruckName === defaultName ? (
                    <b>
                        <i>{cell.getValue()}</i>
                    </b>
                ) : (
                    cell.getValue()
                );
            },
        },
    ];

    if (props.siteMode !== 'PLANNER') {
        columns.push({
            header: '',
            accessorKey: 'actions',
            enableSorting: false,
            muiTableBodyCellProps: { align: 'center', sx: { borderBottom: 'none', padding: '0' } },
            size: 50,
            Cell: ({ cell, row }) => {
                return row.original.TruckName === defaultName ? (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        <IconButton
                            title={t('RoutePlans.Truck.Actions.Edit')}
                            color='primary'
                            className={'icon_button'}
                            style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                            onClick={() => handleEditTruck(row.original)}
                        >
                            <i className='RS_EDIT' />
                        </IconButton>
                    </div>
                ) : (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        <IconButton
                            title={t('RoutePlans.Truck.Actions.Copy')}
                            color='primary'
                            className={'icon_button'}
                            style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                            onClick={() => handleCopyTruck(row.original)}
                        >
                            <i className='RS_COPY' />
                        </IconButton>
                        <IconButton
                            title={t('RoutePlans.Truck.Actions.Edit')}
                            color='primary'
                            className={'icon_button'}
                            style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                            onClick={() => handleEditTruck(row.original)}
                        >
                            <i className='RS_EDIT' />
                        </IconButton>
                        <IconButton
                            title={t('RoutePlans.Truck.Actions.Delete')}
                            color='primary'
                            className={'icon_button'}
                            style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                            onClick={() => handleDeleteTruck(row.original)}
                        >
                            <i className='RS_DELETE' />
                        </IconButton>
                    </div>
                );
            },
        });
    }

    const [data, setData] = React.useState([]);
    const [editTruckDialog, setEditTruckDialog] = React.useState(false);
    const [deleteTruckDialog, setDeleteTruckDialog] = React.useState(false);
    const [mode, setMode] = React.useState('New');
    const [target, setTarget] = React.useState({});
    const [editingName, setEditingName] = React.useState('');

    const [truckSpecialties, setTruckSpecialties] = React.useState([]);

    const [manageSpecialtyDialogOpen, setManageSpecialtyDialogOpen] = React.useState(false);
    const [specialtyList, setSpecialtyList] = useState([]);

    const handleAllServiceSideChange = (event) => {
        try {
            const serviceSideVal = event.target.checked ? 'Same' : 'Both';
            updateAllServiceSide(workspace.value, serviceSideVal).then((res) => {
                let logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 1414,
                    Success: true,
                };
                updateUserActivity(logData);

                // refreshes table
                getTrucks(workspace.value).then((response) => {
                    response.data.map((d) => {
                        d.VehicleCapacity = d.VehicleCapacity[0];
                    });
                    setData(response.data);
                    setRefreshRoutePlanAndRouteSet(true);
                    setRefreshRouteSet(true);
                });
            });
        } catch (error) {
            let logData = {
                WorkspaceId: workspace.value,
                ActionId: 1414,
                Success: false,
            };
            updateUserActivity(logData);

            const message = error.response.data.Message;
            const colonIndex = message.indexOf(':', message.indexOf(':') + 1);
            props.handleDialogOpen(message.substr(0, colonIndex));
        }
    };

    const handleNewTruck = () => {
        const record = data.find((d) => d.TruckName === defaultName);
        if (record) {
            setMode('New');
            if (!record.PickupDeliveryNumStacked) {
                record.PickupDeliveryNumStacked = 1;
            }
            if (!record.PickupCapacityPercentage) {
                record.PickupCapacityRemovals = 1;
            } else {
                record.PickupCapacityRemovals = record.PickupDeliveryNumStacked * record.PickupCapacityPercentage;
            }
            setTarget(record);
            setEditTruckDialog(true);
        }
    };

    const handleCopyTruck = (data) => {
        setMode('Copy');
        if (!data.PickupDeliveryNumStacked) {
            data.PickupDeliveryNumStacked = 1;
        }
        if (!data.PickupCapacityPercentage) {
            data.PickupCapacityRemovals = 1;
        } else {
            data.PickupCapacityRemovals = data.PickupDeliveryNumStacked * data.PickupCapacityPercentage;
        }
        setTarget(data);
        setEditingName(data.TruckName);
        setEditTruckDialog(true);
    };

    const handleEditTruck = (data) => {
        setMode('Edit');

        if (showContainerDelivery) {
            data.PickupCapacityRemovals = Math.round(data.PickupCapacityPercentage * data.PickupDeliveryNumStacked);
        }

        setTarget(data);
        if (data.SkillSetProperties) {
            let s = [];
            data.SkillSetProperties.forEach((ss, i) => {
                let ds = specialtyList.find((d) => d.value === ss);
                if (ds) {
                    s.push({ ...ds, Id: i });
                }
            });
            setTruckSpecialties(s);
        } else {
            setTruckSpecialties([]);
        }
        setEditingName(data.TruckName);
        setEditTruckDialog(true);
    };

    const handleDeleteTruck = (data) => {
        setMode('Delete');
        setTarget(data);
        setDeleteTruckDialog(true);
    };

    const handleEditClose = async (option) => {
        if (option === 'CANCEL') {
            setEditTruckDialog(false);
            setTarget({});
            setTruckSpecialties([]);
        } else if (option === 'SAVE') {
            let data = { ...target };
            if (truckSpecialties.length > 0) {
                let s = [];
                truckSpecialties.forEach((ds) => {
                    if (ds.value) {
                        s.push(ds.value);
                    }
                });
                data.SkillSetProperties = s;
            } else {
                data.SkillSetProperties = [];
            }
            let postData = {
                Id: data.Id,
                WorkspaceId: data.WorkspaceId,
                TruckName: data.TruckName,
                VehicleCapacity: [
                    isNaN(data.VehicleCapacity) ? parseFloat(data.VehicleCapacity.replace(/,/g, '')) : parseFloat(data.VehicleCapacity),
                ],
                CostFixed: isNaN(data.CostFixed) ? parseFloat(data.CostFixed.replace('$', '').replace(/,/g, '')) : parseFloat(data.CostFixed),
                CostVariable: isNaN(data.CostVariable)
                    ? parseFloat(data.CostVariable.replace('$', '').replace(/,/g, ''))
                    : parseFloat(data.CostVariable),
                ServiceSide: data.ServiceSide,
                SkillSetProperties: data.SkillSetProperties,
                CostPerHour: isNaN(data.CostPerHour) ? parseFloat(data.CostPerHour.replace('$', '').replace(/,/g, '')) : parseFloat(data.CostPerHour),
            };

            if (showContainerDelivery) {
                postData.PickupDeliveryNumStacked = data.PickupDeliveryNumStacked;
                postData.PickupCapacityPercentage = +(data.PickupCapacityRemovals / data.PickupDeliveryNumStacked).toFixed(2);
            }

            if (mode === 'New' || mode === 'Copy') {
                try {
                    await postTruck(workspace.value, postData);

                    let logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 1405,
                        Success: true,
                        Metadata: data.TruckName,
                    };
                    updateUserActivity(logData);

                    setEditTruckDialog(false);
                    setTarget({});
                    setTruckSpecialties([]);

                    const response = await getTrucks(workspace.value);
                    response.data.map((d) => {
                        d.VehicleCapacity = d.VehicleCapacity[0];
                    });
                    setData(response.data);
                } catch (e) {
                    let logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 1405,
                        Success: false,
                        Metadata: data.TruckName,
                    };
                    updateUserActivity(logData);

                    const message = JSON.stringify(e.response.data.errors);
                    props.handleDialogOpen(message);
                }
            } else if (mode === 'Edit') {
                try {
                    await putTruck(workspace.value, postData);

                    let logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 1406,
                        Success: true,
                        Metadata: data.TruckName,
                    };
                    updateUserActivity(logData);

                    setEditTruckDialog(false);
                    setTarget({});
                    setTruckSpecialties([]);

                    // refresh table
                    const response = await getTrucks(workspace.value);
                    response.data.map((d) => {
                        d.VehicleCapacity = d.VehicleCapacity[0];
                    });
                    setData(response.data);

                    setRefreshRoutePlanAndRouteSet(true);
                    setRefreshRouteSet(true);
                } catch (e) {
                    let logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 1406,
                        Success: false,
                        Metadata: data.TruckName,
                    };
                    updateUserActivity(logData);

                    const message = JSON.stringify(e.response.data.errors);
                    props.handleDialogOpen(message);
                }
            }
        }
    };

    const handleDeleteClose = async (option) => {
        if (option === 'CANCEL') {
            setDeleteTruckDialog(false);
            setTarget({});
        } else if (option === 'DELETE') {
            try {
                await deleteTruck(workspace.value, target);

                let logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 1407,
                    Success: true,
                    Metadata: target.TruckName,
                };
                updateUserActivity(logData);

                const response = await getTrucks(workspace.value);
                response.data.map((d) => {
                    d.VehicleCapacity = d.VehicleCapacity[0];
                });
                setData(response.data);

                setRefreshRoutePlanAndRouteSet(true);
                setRefreshRouteSet(true);
            } catch (e) {
                let logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 1407,
                    Success: false,
                    Metadata: target.TruckName,
                };
                updateUserActivity(logData);

                const message = e.response.data.Message;
                props.handleDialogOpen(message);
            }
            setDeleteTruckDialog(false);
            setTarget({});
        }
    };

    const updateForm = (e) => {
        let t = { ...target };
        t[e.target.name] = e.target.value;
        if (e.target.name === 'ServiceSide') {
            t[e.target.name] = target.ServiceSide === 'Same' ? 'Both' : 'Same';
        }
        if (e.target.name === 'PickupDeliveryNumStacked') {
            if (e.target.value < target.PickupCapacityRemovals) {
                t['PickupCapacityRemovals'] = e.target.value;
            }
        }
        setTarget(t);
    };

    const validForm = () => {
        if (!target.TruckName) {
            return false;
        }
        if (mode === 'New') {
            let duplicate = data.filter((d) => d.TruckName.toLowerCase() === target.TruckName.toLowerCase());
            if (duplicate.length > 0) {
                return false;
            }
        } else if (mode === 'Copy' || mode === 'Edit') {
            let duplicate = data.filter(
                (d) => d.TruckName.toLowerCase() === target.TruckName.toLowerCase() && target.TruckName.toLowerCase() !== editingName.toLowerCase(),
            );
            if (duplicate.length > 0) {
                return false;
            }
        }
        if (!target.VehicleCapacity) {
            return false;
        }
        if (isNaN(target.VehicleCapacity) && target.VehicleCapacity.replace(/,/g, '') <= 0) {
            return false;
        } else if (target.VehicleCapacity <= 0) {
            return false;
        }
        return true;
    };

    const isAllSameServiceSide = (data) => !data.some((truck) => truck.ServiceSide === 'Both');

    useEffect(() => {
        if (workspace && workspace.value !== 0) {
            getTrucks(workspace.value).then(
                (res) => {
                    res.data.map((d) => {
                        d.VehicleCapacity = d.VehicleCapacity[0];
                    });
                    setData(res.data);
                },
                (err) => {
                    setData([]);
                },
            );
            getSpecialties(workspace.value).then((res) => {
                let s = res.data.map((d) => {
                    return {
                        label: d.Specialty,
                        value: d.Id,
                    };
                });
                setSpecialtyList(s);
            });
        }
    }, [workspace]);

    useEffect(() => {
        if (workspace && !manageSpecialtyDialogOpen) {
            getSpecialties(workspace.value).then((res) => {
                let s = res.data.map((d) => {
                    return {
                        label: d.Specialty,
                        value: d.Id,
                    };
                });
                setSpecialtyList(s);
            });
        }
    }, [manageSpecialtyDialogOpen]);

    useEffect(() => {
        if (workspace && workspace.value !== 0 && editTruckDialog) {
            getSpecialties(workspace.value).then((res) => {
                let s = res.data.map((d) => {
                    return {
                        label: d.Specialty,
                        value: d.Id,
                    };
                });
                setSpecialtyList(s);
            });
        }
    }, [editTruckDialog]);

    useEffect(() => {
        let options = [...specialtyList];
        options.map((o) => {
            if (truckSpecialties.find((ds) => ds.value === o.value)) {
                o.isDisabled = true;
            } else {
                o.isDisabled = false;
            }
            return o;
        });
        setSpecialtyList(options);
    }, [truckSpecialties]);

    const addTruckSpecialty = () => {
        let newTruckSpecialties = [...truckSpecialties];
        const id = Math.random();
        if (newTruckSpecialties.filter((s) => s.Id === id).length === 0) {
            newTruckSpecialties.push({ Id: id, label: null, value: null });
        } else {
            newTruckSpecialties.push({ Id: -id, label: null, value: null });
        }
        setTruckSpecialties(newTruckSpecialties);
    };

    const updateTruckSpecialties = (e, i) => {
        let s = [...truckSpecialties];
        s.map((sl) => {
            if (sl.Id === i) {
                sl.label = e.label;
                sl.value = e.value;
            }
            return sl;
        });
        setTruckSpecialties(s);
    };

    const deleteTruckSpecialty = (id) => {
        let newTruckSpecialties = truckSpecialties.filter((x) => x.Id !== id);
        setTruckSpecialties(newTruckSpecialties);
    };

    return (
        <Grid
            item
            xs={12}
            style={{ zIndex: 100 }}
        >
            <div style={{ maxWidth: '100%', padding: '5px', height: isNaN(truckContainerHeight) ? '387px' : truckContainerHeight + 'px' }}>
                <div style={{ position: 'relative', height: '30px' }}>
                    <span style={{ fontSize: '18px', margin: '5px 10px' }}>{title}</span>
                    {props.siteMode !== 'PLANNER' && (
                        <IconButton
                            title={t('RoutePlans.Truck.Actions.New')}
                            color='primary'
                            style={{ position: 'absolute', top: '-5px', right: '25px', padding: 0, color: '#16a8a6' }}
                            onClick={() => handleNewTruck()}
                        >
                            <i className='RS_ADD' />
                        </IconButton>
                    )}
                </div>
                <MaterialReactTable
                    columns={columns}
                    data={data}
                    enableTopToolbar={false}
                    enableStickyHeader={true}
                    enableColumnFilters={false}
                    enablePagination={false}
                    enableBottomToolbar={false}
                    enableColumnActions={false}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableHiding={false}
                    initialState={{
                        density: 'compact',
                        showGlobalFilter: false,
                        sorting: [{ id: 'TruckName', desc: false }],
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: isNaN(truckGridHeight) ? '340px' : truckGridHeight + 'px' },
                    }}
                />
            </div>
            <EditDialogue
                editTruckDialog={editTruckDialog}
                mode={mode}
                title={'Truck'}
                updateForm={updateForm}
                target={target}
                defaultName={defaultName}
                handleEditClose={handleEditClose}
                editingName={editingName}
                validForm={validForm}
                addTruckSpecialty={addTruckSpecialty}
                truckSpecialties={truckSpecialties}
                deleteTruckSpecialty={deleteTruckSpecialty}
                setManageSpecialtyDialogOpen={setManageSpecialtyDialogOpen}
                specialtyList={specialtyList}
                updateTruckSpecialties={updateTruckSpecialties}
            />
            <DeleteDialogue
                deleteTruckDialog={deleteTruckDialog}
                mode={mode}
                title={'Truck'}
                target={target}
                handleDeleteClose={handleDeleteClose}
            />
            <ManagementSpecialtyDialog
                workspace={workspace}
                manageSpecialtyDialogOpen={manageSpecialtyDialogOpen}
                setManageSpecialtyDialogOpen={setManageSpecialtyDialogOpen}
            />
        </Grid>
    );
};

export default TruckTable;
