import React, { useState, useRef } from 'react';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
//ESRI
import { Extent } from '@arcgis/core/geometry';
//RPW
import { getFormattedTime, timeStringToFloat } from '../../_helpers/common';
import {
    getFacilities,
    addFacility,
    updateFacility,
    deleteFacility,
    getSideOfStreet,
    pinFacility,
    unpinFacility,
} from '../../_actions/MapPage.actions';
//DevExpress
import 'devextreme/dist/css/dx.light.compact.css';
import DataGrid, {
    Column,
    Scrolling,
    Sorting,
    Selection,
    GroupPanel,
    Grouping,
    Pager,
    Paging,
    FilterRow,
    FilterPanel,
    HeaderFilter,
    SearchPanel,
    ColumnChooser,
    ColumnFixing,
    Toolbar,
    Item,
} from 'devextreme-react/data-grid';
import { Button as DxButton, TextBox, CheckBox } from 'devextreme-react';
import Select from 'react-select';
import { updateUserActivity } from '../../_actions/Global.actions';
import { durationOptions, penaltyOptions, facilityTimeOfDayOptions } from '../../_helpers/common';
import { CurrencyInput } from '../Manage/ManageController';
import { Margin } from 'devextreme-react/bar-gauge';

const FacilityTable = (props) => {
    const title = 'Facilities';

    const refFacilityListGrid = useRef();
    //Store
    const { workspace, siteMode, project, refreshFacilityLayer, bottomHeight, refreshFacilities, setRefreshFacilities } = props;
    //State
    const [data, setData] = useState([]);
    const [facilityData, setFacilityData] = useState([]);
    const [facilityListView, setFacilityListView] = useState('All');
    const [unpinnedIds, setUnpinnedIds] = useState([]);
    const [pinnedIds, setPinnedIds] = useState([]);
    const [deleteFacilityDialog, setDeleteFacilityDialog] = useState(false);
    const [unpinFacilityDialog, setUnpinFacilityDialog] = useState(false);
    const [deleteFacilityOption, setDeleteFacilityOption] = useState(null);
    const [deleteFacilityGid, setDeleteFacilityGid] = useState(null);
    const [showFilterRow, setShowFilterRow] = useState(false);
    const [showFilterPanel, setShowFilterPanel] = useState(false);
    const [showGroupPanel, setShowGroupPanel] = useState(false);
    const [expandAllGroupRows, setExpandAllGroupRows] = useState(false);

    React.useEffect(() => {
        if (project) {
            PopulateFacilities();
        }
    }, [project]);

    React.useEffect(() => {
        if (refFacilityListGrid && refFacilityListGrid.current && refFacilityListGrid.current.instance) {
            refFacilityListGrid.current.instance.clearFilter();
        }
    }, [workspace]);

    React.useEffect(() => {
        if (props.pinFacility) {
            props.setPinFacility(false);
            if (props.selectedFacilityIds.length === 1) {
                handlePinClose('PIN');
            } else {
                props.setPinFacilityDialog(true);
            }
        }
    }, [props.pinFacility]);

    React.useEffect(() => {
        if (refreshFacilities) {
            PopulateFacilities();
            setRefreshFacilities(false);
        }
    }, [refreshFacilities]);

    const handleEditFacility = (data) => {
        props.setMode('Edit');
        props.setFacilityTarget(data);
        props.setEditFacilityDialog(true);
        props.setIsFacilityFormValid(true);
    };

    const handleDeleteFacility = (facility) => {
        setDeleteFacilityGid(facility.Gid);
        setDeleteFacilityOption('DELETE');
        setDeleteFacilityDialog(true);
    };

    const handleDeleteClose = async (option) => {
        if (option === 'CANCEL') {
            setDeleteFacilityDialog(false);
        } else if (option === 'DELETE') {
            if (refFacilityListGrid && refFacilityListGrid.current && refFacilityListGrid.current.instance) {
                refFacilityListGrid.current.instance.beginCustomLoading();
            }
            if (deleteFacilityGid) {
                try {
                    await deleteFacility(workspace.value, deleteFacilityGid, siteMode, project);
                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 204,
                        Mode: siteMode,
                        Success: true,
                        Metadata: deleteFacilityGid,
                    };
                    updateUserActivity(logData);
                } catch (e) {
                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 204,
                        Mode: siteMode,
                        Success: false,
                        Metadata: deleteFacilityGid,
                    };
                    updateUserActivity(logData);
                    props.setOpenMapAlert(true);
                    props.setMapAlertTitle('Error');
                    props.setMapAlertMessage(`${e.response.data.Message}`);
                }
            }
            PopulateFacilities();
            refreshFacilityLayer();
        } else if (option === 'DELETE_SELECTED') {
            if (refFacilityListGrid && refFacilityListGrid.current && refFacilityListGrid.current.instance) {
                refFacilityListGrid.current.instance.beginCustomLoading();
            }
            for (let i = 0; i < props.selectedFacilityIds.length; i++) {
                try {
                    await deleteFacility(workspace.value, props.selectedFacilityIds[i], siteMode, project);
                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 204,
                        Mode: siteMode,
                        Success: true,
                        Metadata: props.selectedFacilityIds[i],
                    };
                    updateUserActivity(logData);
                } catch (e) {
                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 204,
                        Mode: siteMode,
                        Success: false,
                        Metadata: props.selectedFacilityIds[i],
                    };
                    updateUserActivity(logData);
                }
            }
            PopulateFacilities();
            refreshFacilityLayer();
        }
        setDeleteFacilityDialog(false);
    };

    const handlePinClose = async (option) => {
        if (option === 'CANCEL') {
            props.setPinFacilityDialog(false);
        } else if (option === 'PIN') {
            if (refFacilityListGrid && refFacilityListGrid.current && refFacilityListGrid.current.instance) {
                refFacilityListGrid.current.instance.beginCustomLoading();
            }
            let sideOfStreetResult = await getSideOfStreet(props.pickedLinkId, props.pickedXY.X, props.pickedXY.Y);
            for (let i = 0; i < props.selectedFacilityIds.length; i++) {
                if (props.selectedFacilityIds > 0) {
                    try {
                        await pinFacility(
                            workspace.value,
                            props.selectedFacilityIds[i],
                            props.pickedLinkId,
                            props.pickedXY.X,
                            props.pickedXY.Y,
                            sideOfStreetResult.data,
                            siteMode,
                            project,
                        );
                        props.setSelectedFacilityIds([]);
                        const logData = {
                            WorkspaceId: workspace.value,
                            ActionId: 205,
                            Mode: siteMode,
                            Success: false,
                            Metadata: props.selectedFacilityIds[i],
                        };
                        updateUserActivity(logData);
                    } catch (e) {
                        props.setSelectedFacilityIds([]);
                        const logData = {
                            WorkspaceId: workspace.value,
                            ActionId: 205,
                            Mode: siteMode,
                            Success: false,
                            Metadata: props.selectedFacilityIds[i],
                        };
                        updateUserActivity(logData);
                    }
                }
            }
            props.setPinFacilityDialog(false);
            PopulateFacilities();
            refreshFacilityLayer();
        }
        props.pickXYGraphicsLayer.removeAll();
        props.setSelStreetMaintenanceMode(null);
    };

    const handleUnpinFacility = () => {
        setUnpinFacilityDialog(true);
    };

    const handleUnpinClose = async (option) => {
        if (option === 'CANCEL') {
            setUnpinFacilityDialog(false);
        } else if (option === 'UNPIN') {
            if (refFacilityListGrid && refFacilityListGrid.current && refFacilityListGrid.current.instance) {
                refFacilityListGrid.current.instance.beginCustomLoading();
            }
            for (let i = 0; i < props.selectedFacilityIds.length; i++) {
                if (props.selectedFacilityIds > 0) {
                    try {
                        await unpinFacility(workspace.value, props.selectedFacilityIds[i], siteMode, project);
                        props.setSelectedFacilityIds([]);
                        const logData = {
                            WorkspaceId: workspace.value,
                            ActionId: 206,
                            Mode: siteMode,
                            Success: true,
                            Metadata: props.selectedFacilityIds[i],
                        };
                        updateUserActivity(logData);
                    } catch (e) {
                        props.setSelectedFacilityIds([]);
                        const logData = {
                            WorkspaceId: workspace.value,
                            ActionId: 206,
                            Mode: siteMode,
                            Success: false,
                            Metadata: props.selectedFacilityIds[i],
                        };
                        updateUserActivity(logData);
                    }
                }
            }
            setUnpinFacilityDialog(false);
            PopulateFacilities();
            refreshFacilityLayer();
        }
    };

    const zoomToFacilities = (selFacilityXYs) => {
        if (selFacilityXYs.length > 0) {
            var xs = selFacilityXYs.map(function (loc) {
                return loc[0];
            });
            var ys = selFacilityXYs.map(function (loc) {
                return loc[1];
            });

            var MinX = Math.min.apply(null, xs);
            var MinY = Math.min.apply(null, ys);
            var MaxX = Math.max.apply(null, xs);
            var MaxY = Math.max.apply(null, ys);

            if (MinX == MaxX && MinY == MaxY) {
                props.mapView
                    .goTo({
                        center: [MinX, MaxY],
                        zoom: 17,
                    })
                    .catch(function (error) {
                        if (error.name != 'AbortError') {
                            console.error(error);
                        }
                    });
            } else {
                let facilityExtent = new Extent({ xmin: MinX, ymin: MinY, xmax: MaxX, ymax: MaxY });
                props.mapView.extent = facilityExtent.expand(1.5);
            }
        }
    };

    const setFacilityDataByView = (facilityRows) => {
        if (facilityRows) {
            let i,
                pinnedIds = [],
                pinnedData = [],
                unpinnedIds = [],
                unpinnedData = [];

            for (i = 0; i < facilityRows.length; i++) {
                if (facilityRows[i].LinkId <= 0) {
                    unpinnedIds.push(facilityRows[i].Gid);
                    unpinnedData.push(facilityRows[i]);
                }
                if (facilityRows[i].Score === -1) {
                    pinnedIds.push(facilityRows[i].Gid);
                    pinnedData.push(facilityRows[i]);
                }
            }
            setUnpinnedIds(unpinnedIds);
            setPinnedIds(pinnedIds);

            switch (facilityListView) {
                case 'All':
                    setData(facilityRows);
                    break;
                case 'Selected':
                    let selectedData = facilityRows.filter((row) => props.selectedFacilityIds.includes(row.Gid));
                    setData(selectedData);
                    break;
                case 'Unpinned':
                    setData(unpinnedData);
                    break;
                case 'Pinned':
                    setData(pinnedData);
                    break;
                default:
                    break;
            }
        }
    };

    const PopulateFacilities = async () => {
        if (workspace && workspace.value > 0) {
            const response = await getFacilities(siteMode, workspace.value, project);
            response.data = response.data.map((r) => {
                r.Duration = getFormattedTime(r.Duration);
                r.Penalty = getFormattedTime(r.Penalty);
                r.EarliestArrival = getFormattedTime(r.EarliestArrival);
                r.LatestArrival = getFormattedTime(r.LatestArrival);
                return r;
            });

            let sortedData = response.data.sort((a, b) => a.Id - b.Id);
            setFacilityData(sortedData);
            setFacilityDataByView(sortedData);
        } else {
            setFacilityData([]);
        }
        if (refFacilityListGrid && refFacilityListGrid.current && refFacilityListGrid.current.instance) {
            refFacilityListGrid.current.instance.endCustomLoading();
        }
    };

    React.useEffect(() => {
        if (refFacilityListGrid && refFacilityListGrid.current && refFacilityListGrid.current.instance) {
            refFacilityListGrid.current.instance.beginCustomLoading();
        }
        PopulateFacilities();
    }, [workspace]);

    React.useEffect(() => {
        setFacilityDataByView(facilityData);
    }, [facilityListView]);

    React.useEffect(() => {
        //Validate facility form
        if (!props.facilityTarget.Name || props.facilityTarget.Name === '' || (props.facilityTarget.Intermediate && !props.facilityTarget.Duration)) {
            props.setIsFacilityFormValid(false);
        } else {
            props.setIsFacilityFormValid(true);
        }
    }, [props.facilityTarget]);

    //UI
    return (
        <div>
            <DataGrid
                ref={refFacilityListGrid}
                style={{ height: bottomHeight }}
                dataSource={data}
                keyExpr='Gid'
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                allowColumnResizing={true}
                columnResizingMode={'nextColumn'}
                columnAutoWidth={false}
                selectedRowKeys={props.selectedFacilityIds}
                onSelectionChanged={(e) => {
                    if (!window.userSelect) {
                        window.userSelect = true;
                        props.setSelectedFacilityIds(e.selectedRowKeys);
                    }
                }}
                onCellPrepared={(e) => {
                    if (e.data) {
                        if (unpinnedIds.includes(e.data.Gid)) {
                            e.cellElement.style.color = 'rgba(255, 0, 0, 1)';
                        } else if (pinnedIds.includes(e.data.Gid)) {
                            e.cellElement.style.color = 'rgba(25, 118, 210, 1)';
                        }
                    }
                }}
            >
                <SearchPanel
                    visible={true}
                    width={240}
                    placeholder='Search...'
                />
                <HeaderFilter visible={false} />
                <FilterRow
                    visible={showFilterRow}
                    applyFilter={true}
                />
                <FilterPanel visible={showFilterPanel} />
                <Scrolling rowRenderingMode='virtual'></Scrolling>
                <Paging defaultPageSize={10000} />
                <GroupPanel visible={showGroupPanel} />
                <Grouping autoExpandAll={expandAllGroupRows} />
                <Pager
                    visible={true}
                    displayMode={'full'}
                    showPageSizeSelector={false}
                    showInfo={false}
                    showNavigationButtons={false}
                />
                <ColumnChooser enabled={true} />
                <ColumnFixing enabled={false} />
                <Selection
                    mode='multiple'
                    selectAllMode={'allPages'}
                    allowSelectAll={true}
                    showCheckBoxesMode={'onClick'}
                    width='30px'
                />
                <Scrolling mode='virtual' />
                <Sorting
                    mode='multiple'
                    showSortIndexes='true'
                />
                <Toolbar>
                    <Item location='before'>
                        <div className='dx-fieldset-header'>&nbsp;{title}&nbsp;&nbsp;</div>
                    </Item>
                    <Item location='before'>
                        <DxButton
                            text={'All (' + facilityData.length + ')'}
                            type={facilityListView === 'All' ? 'success' : 'normal'}
                            onClick={() => {
                                setFacilityListView('All');
                            }}
                        ></DxButton>
                    </Item>
                    <Item location='before'>
                        <DxButton
                            text={'Selected (' + props.selectedFacilityIds.length + ')'}
                            type={facilityListView === 'Selected' ? 'success' : 'normal'}
                            onClick={() => {
                                setFacilityListView('Selected');
                            }}
                        ></DxButton>
                    </Item>
                    <Item location='before'>
                        <DxButton
                            text={'Unpinned (' + unpinnedIds.length + ')'}
                            type={facilityListView === 'Unpinned' ? 'danger' : 'normal'}
                            onClick={() => {
                                setFacilityListView('Unpinned');
                            }}
                        ></DxButton>
                    </Item>
                    <Item location='before'>
                        <DxButton
                            text={'Pinned (' + pinnedIds.length + ')'}
                            type={facilityListView === 'Pinned' ? 'default' : 'normal'}
                            onClick={() => {
                                setFacilityListView('Pinned');
                            }}
                        ></DxButton>
                    </Item>
                    <Item location='before'>
                        <DxButton
                            hint='Zoom to selected facilities'
                            disabled={
                                props.selectedFacilityIds.length > 0 && !unpinnedIds.some((it) => props.selectedFacilityIds.includes(it))
                                    ? false
                                    : true
                            }
                            onClick={() => {
                                let selFacilityXYs = [];
                                facilityData.forEach((row) => {
                                    if (props.selectedFacilityIds.includes(row.Gid)) {
                                        selFacilityXYs.push([row.X, row.Y]);
                                    }
                                });
                                zoomToFacilities(selFacilityXYs);
                            }}
                        >
                            <i className={'RS_ZOOM_TO_SELECT'}></i>
                        </DxButton>
                    </Item>
                    <Item location='before'>
                        <DxButton
                            hint='Add new facility'
                            onClick={() => {
                                props.setMapSelectionMode('FC_ADD');
                                props.setMapInfoMessage('Click on Map to specify Facility location');
                                props.pickXYSketch.create('point');
                            }}
                        >
                            <i className={'RS_ADD_FACILITY'}></i>
                        </DxButton>
                    </Item>
                    {/*<Item location="before">*/}
                    {/*    <DxButton hint="Delete selected facilities" disabled={props.selectedFacilityIds.length > 0 ? false : true}*/}
                    {/*              onClick={() => {*/}
                    {/*                  setDeleteFacilityOption("DELETE_SELECTED");*/}
                    {/*                  setDeleteFacilityDialog(true);*/}
                    {/*              }}>*/}
                    {/*        <i className={'RS_DELETE_FACILITY'}></i>*/}
                    {/*    </DxButton>*/}
                    {/*</Item>*/}
                    <Item location='before'>
                        <DxButton
                            hint='Pin selected facilities'
                            disabled={props.selectedFacilityIds.length > 0 ? false : true}
                            onClick={() => {
                                props.setMapSelectionMode('FC_PIN');
                                props.setMapInfoMessage('Click on map to specify facility location');
                                props.pickXYSketch.create('point');
                            }}
                        >
                            <i className={'RS_PIN'}></i>
                        </DxButton>
                    </Item>
                    <Item location='before'>
                        <DxButton
                            hint='Unpin selected facilities'
                            disabled={props.selectedFacilityIds.length > 0 ? false : true}
                            onClick={() => {
                                handleUnpinFacility();
                            }}
                        >
                            <i className={'RS_UNPIN'}></i>
                        </DxButton>
                    </Item>
                    <Item
                        location='after'
                        name='searchPanel'
                    />
                </Toolbar>
                <Column
                    width='60'
                    visibleIndex={0}
                    cellRender={(data) => {
                        return (
                            <>
                                <span
                                    title='Edit Facility Info'
                                    icon={'RS_EDIT'}
                                    onClick={(e) => {
                                        handleEditFacility(data.row.data);
                                    }}
                                >
                                    <div style={{ display: 'inline-block' }}>
                                        <i className={'RS_EDIT'}></i>
                                    </div>
                                </span>
                                <span
                                    title='Delete Facility Info'
                                    icon={'RS_DELETE_FACILITY'}
                                    onClick={(e) => {
                                        handleDeleteFacility(data.row.data);
                                    }}
                                >
                                    <div style={{ display: 'inline-block' }}>
                                        <i className={'RS_DELETE_FACILITY'}></i>
                                    </div>
                                </span>
                            </>
                        );
                    }}
                />
                <Column
                    dataField='Name'
                    caption='Facility Name'
                    visibleIndex={1}
                ></Column>
                <Column
                    dataField='Id'
                    caption='Facility ID'
                    visibleIndex={1}
                    visible={false}
                ></Column>
                <Column
                    dataField='Address'
                    visibleIndex={2}
                ></Column>
                <Column
                    dataField='State'
                    visibleIndex={3}
                ></Column>
                <Column
                    dataField='City'
                    visibleIndex={4}
                ></Column>
                <Column
                    dataField='PostalCode'
                    caption='ZIP'
                    visibleIndex={5}
                ></Column>
                <Column
                    dataField='Material'
                    caption='Material'
                    visibleIndex={6}
                    visible={workspace.type === 'Roll-off'}
                ></Column>
                <Column
                    dataField='Containers'
                    caption='Containers'
                    visibleIndex={7}
                    visible={workspace.type === 'Roll-off'}
                ></Column>
                <Column
                    dataField='StartEnd'
                    caption='Start/End'
                    visibleIndex={8}
                ></Column>
                <Column
                    dataField='Intermediate'
                    visibleIndex={9}
                ></Column>
                <Column
                    dataField='Duration'
                    visibleIndex={10}
                    cellRender={(e) => {
                        const filteredOption = durationOptions.filter((item) => item.value === e.data.Duration);
                        const cellLabel = e.data.Intermediate ? (filteredOption.length > 0 ? filteredOption[0].label : '') : '';
                        return <span>{cellLabel}</span>;
                    }}
                ></Column>
                <Column
                    dataField='TippingCost'
                    caption='Tipping Fee'
                    visibleIndex={11}
                    cellRender={(e) => {
                        if (e.data.TippingCost) {
                            return <span>${e.data.TippingCost.toFixed(4)}</span>;
                        } else {
                            return <span>{'$0'}</span>;
                        }
                    }}
                ></Column>
                <Column
                    dataField='EarliestArrival'
                    caption='Open'
                    visibleIndex={12}
                    cellRender={(e) => {
                        const filteredOption = facilityTimeOfDayOptions.filter((item) => item.value === e.data.EarliestArrival);
                        const cellLabel = e.data.Intermediate ? (filteredOption.length > 0 ? filteredOption[0].label : '') : '';
                        return <span>{cellLabel}</span>;
                    }}
                ></Column>
                <Column
                    dataField='LatestArrival'
                    caption='Close'
                    visibleIndex={13}
                    cellRender={(e) => {
                        const filteredOption = facilityTimeOfDayOptions.filter((item) => item.value === e.data.LatestArrival);
                        const cellLabel = e.data.Intermediate ? (filteredOption.length > 0 ? filteredOption[0].label : '') : '';
                        return <span>{cellLabel}</span>;
                    }}
                ></Column>
                <Column
                    dataField='LinkId'
                    caption='Segment ID'
                    visibleIndex={14}
                ></Column>
                <Column
                    dataField='Side'
                    visibleIndex={15}
                ></Column>
            </DataGrid>

            <Dialog
                open={deleteFacilityDialog}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>{'Delete ' + (props.selectedFacilityIds.length > 1 ? ' Facilities' : ' Facility?')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Are you sure you want to delete selected{' '}
                        {props.selectedFacilityIds.length > 1 ? props.selectedFacilityIds.length + ' facilities?' : ' facility?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                        style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                        onClick={() => handleDeleteClose('CANCEL')}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                        onClick={() => handleDeleteClose(deleteFacilityOption)}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={props.pinFacilityDialog}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>{'Pin ' + (props.selectedFacilityIds.length > 1 ? ' Facilities' : ' Facility?')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Are you sure you want to pin selected{' '}
                        {props.selectedFacilityIds.length > 1 ? props.selectedFacilityIds.length + ' facilities' : ' facility'} to highlighted
                        location?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                        style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                        onClick={() => handlePinClose('CANCEL')}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                        onClick={() => handlePinClose('PIN')}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={unpinFacilityDialog}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle>{'Unpin ' + (props.selectedFacilityIds.length > 1 ? ' Facilities' : ' Facility?')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Are you sure you want to unpin selected{' '}
                        {props.selectedFacilityIds.length > 1 ? props.selectedFacilityIds.length + ' facilities?' : ' facility?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                        style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                        onClick={() => handleUnpinClose('CANCEL')}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                        onClick={() => handleUnpinClose('UNPIN')}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export const EditFacilityDialog = (props) => {
    const {
        workspace,
        siteMode,
        project,
        refreshFacilityLayer,
        editFacilityDialog,
        setEditFacilityDialog,
        mode,
        facilityTarget,
        setFacilityTarget,
        isFacilityFormValid,
        setOpenMapAlert,
        setMapAlertTitle,
        setMapAlertMessage,
        pickXYGraphicsLayer,
        setPickedLinkId,
        setPickedXY,
        setSelStreetMaintenanceMode,
        setRefreshFacilities,
        pickedXY,
        pickedLinkId,
    } = props;

    const updateForm = (field, value) => {
        let t = { ...facilityTarget };
        t[field] = value;
        //Default duration when Intermediate flag is turned on
        if (field === 'Intermediate') {
            t['Duration'] = durationOptions[3].value;
            t['EarliestArrival'] = 0;
            t['LatestArrival'] = 0;
            t['TippingCost'] = 0;
        }
        setFacilityTarget(t);
    };

    const handleEditClose = async (option) => {
        if (option === 'CANCEL') {
            setEditFacilityDialog(false);
            setFacilityTarget({});
            pickXYGraphicsLayer.removeAll();
            setPickedLinkId(null);
            setPickedXY({});
            setSelStreetMaintenanceMode(null);
        } else if (option === 'SAVE') {
            let target = { ...props.facilityTarget };
            if (!target.Intermediate) {
                target.Duration = 0;
                target.Penalty = 0;
                target.EarliestArrival = 0;
                target.LatestArrival = 0;
                target.TippingCost = 0;
            } else {
                target.Duration = target.Duration ? timeStringToFloat(target.Duration) : 0;
                target.Penalty = target.Penalty ? timeStringToFloat(target.Penalty) : 0;
                target.EarliestArrival = target.EarliestArrival ? timeStringToFloat(target.EarliestArrival) : 0;
                target.LatestArrival = target.LatestArrival ? timeStringToFloat(target.LatestArrival) : 0;
                target.TippingCost = isNaN(target.TippingCost)
                    ? parseFloat(target.TippingCost.replace('$', '').replace(/,/g, ''))
                    : parseFloat(target.TippingCost);
            }
            if (props.mode === 'New') {
                try {
                    let data = {
                        WorkspaceID: workspace.value,
                        Name: target.Name,
                        StartEndId: 0,
                        Address: target.Address,
                        City: target.City,
                        State: target.State,
                        PostalCode: target.PostalCode,
                        StartEnd: target.StartEnd ? true : false,
                        Intermediate: target.Intermediate ? true : false,
                        Penalty: target.Penalty,
                        Duration: target.Duration,
                        EarliestArrival: target.EarliestArrival,
                        LatestArrival: target.LatestArrival,
                        LinkId: pickedLinkId,
                        X: pickedXY.X,
                        Y: pickedXY.Y,
                        Status: 'M',
                        TippingCost: target.TippingCost,
                    };

                    if (workspace.type === 'Roll-off' && target.Intermediate) {
                        data.Material = target.Material;
                        data.Containers = target.Containers;
                    }

                    await addFacility(workspace.value, data, siteMode, project);

                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 202,
                        Mode: siteMode,
                        Success: true,
                        Metadata: target.Name,
                    };
                    updateUserActivity(logData);

                    setEditFacilityDialog(false);
                    setFacilityTarget({});
                    setRefreshFacilities(true);
                    refreshFacilityLayer();
                } catch (e) {
                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 202,
                        Mode: siteMode,
                        Success: false,
                        Metadata: target.Name,
                    };
                    updateUserActivity(logData);
                    setOpenMapAlert(true);
                    setMapAlertTitle('Error');
                    setMapAlertMessage(`${JSON.stringify(e.response.data)}`);
                }
                pickXYGraphicsLayer.removeAll();
                setPickedLinkId(null);
                setPickedXY({});
                setSelStreetMaintenanceMode(null);
            } else if (mode === 'Edit') {
                try {
                    let data = [
                        { op: 'replace', path: '/Name', value: target.Name },
                        { op: 'replace', path: '/StartEndId', value: 0 },
                        { op: 'replace', path: '/Address', value: target.Address },
                        { op: 'replace', path: '/City', value: target.City },
                        { op: 'replace', path: '/State', value: target.State },
                        { op: 'replace', path: '/PostalCode', value: target.PostalCode },
                        { op: 'replace', path: '/StartEnd', value: target.StartEnd },
                        { op: 'replace', path: '/Intermediate', value: target.Intermediate },
                        { op: 'replace', path: '/Duration', value: target.Duration },
                        { op: 'replace', path: '/Penalty', value: target.Penalty },
                        { op: 'replace', path: '/EarliestArrival', value: target.EarliestArrival },
                        { op: 'replace', path: '/LatestArrival', value: target.LatestArrival },
                        {
                            op: 'replace',
                            path: '/TippingCost',
                            value: isNaN(target.TippingCost)
                                ? parseFloat(target.TippingCost.replace('$', '').replace(/,/g, ''))
                                : parseFloat(target.TippingCost),
                        },
                    ];

                    if (workspace.type === 'Roll-off' && target.Intermediate) {
                        data.push({ op: 'replace', path: '/Material', value: target.Material });
                        data.push({ op: 'replace', path: '/Containers', value: target.Containers });
                    } else if (workspace.type === 'Roll-off' && !target.Intermediate) {
                        data.push({ op: 'replace', path: '/Material', value: '' });
                        data.push({ op: 'replace', path: '/Containers', value: false });
                    }

                    await updateFacility(workspace.value, target.Gid, data, siteMode, project);
                    const logData = {
                        WorkspaceId: workspace.value,
                        Mode: siteMode,
                        ActionId: 204,
                        Success: true,
                        Metadata: target.Name,
                    };
                    updateUserActivity(logData);
                    setEditFacilityDialog(false);
                    setFacilityTarget({});
                    setRefreshFacilities(true);
                    refreshFacilityLayer();
                } catch (e) {
                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 204,
                        Mode: siteMode,
                        Success: false,
                        Metadata: target.Name,
                    };
                    updateUserActivity(logData);
                    setOpenMapAlert(true);
                    setMapAlertTitle('Error');
                    setMapAlertMessage(`${e}`);
                }
            }
        }
    };

    return (
        <Dialog
            open={editFacilityDialog}
            maxWidth={'xs'}
            fullWidth={true}
        >
            {/*style={{ cursor: 'move' }}*/}
            <DialogTitle>{mode} Facility</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={'10px'}
                    style={{ paddingTop: '10px' }}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Typography
                            variant='body1'
                            gutterBottom
                            style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                        >
                            Facility Name *
                        </Typography>
                        <TextBox
                            name='Name'
                            defaultValue={facilityTarget.Name}
                            onValueChanged={(e) => {
                                updateForm('Name', e.value);
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Typography
                            variant='body1'
                            gutterBottom
                            style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                        >
                            Address
                        </Typography>
                        <TextBox
                            name='Address'
                            defaultValue={facilityTarget.Address}
                            onValueChanged={(e) => {
                                updateForm('Address', e.value);
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <Typography
                            variant='body1'
                            gutterBottom
                            style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                        >
                            City
                        </Typography>
                        <TextBox
                            name='City'
                            defaultValue={facilityTarget.City}
                            onValueChanged={(e) => {
                                updateForm('City', e.value);
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={3}
                    >
                        <Typography
                            variant='body1'
                            gutterBottom
                            style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                        >
                            State
                        </Typography>
                        <TextBox
                            name='State'
                            defaultValue={facilityTarget.State}
                            onValueChanged={(e) => {
                                updateForm('State', e.value);
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={3}
                    >
                        <Typography
                            variant='body1'
                            gutterBottom
                            style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                        >
                            Zip
                        </Typography>
                        <TextBox
                            name='PostalCode'
                            defaultValue={facilityTarget.PostalCode}
                            onValueChanged={(e) => {
                                updateForm('PostalCode', e.value);
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        justifyContent='center'
                    >
                        <CheckBox
                            name='StartEnd'
                            text={'Start/End'}
                            value={facilityTarget.StartEnd}
                            onValueChanged={(e) => {
                                updateForm('StartEnd', e.value);
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        justifyContent='center'
                    >
                        <CheckBox
                            name='Intermediate'
                            text={'Intermediate'}
                            value={facilityTarget.Intermediate}
                            onValueChanged={(e) => {
                                updateForm('Intermediate', e.value);
                            }}
                        />
                    </Grid>
                </Grid>
                {facilityTarget.Intermediate && (
                    <Grid
                        container
                        spacing={1}
                        style={{ paddingTop: '10px' }}
                    >
                        <Grid
                            item
                            xs={6}
                        >
                            <Typography
                                variant='body1'
                                gutterBottom
                                style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                            >
                                Duration
                            </Typography>
                            <Select
                                className='basic-single'
                                classNamePrefix='select'
                                isClearable={false}
                                isSearchable={true}
                                name='Duration'
                                value={durationOptions.find((o) => o.value == facilityTarget.Duration)}
                                onChange={(e) => updateForm('Duration', e.value)}
                                options={durationOptions}
                                defaultValue={durationOptions[3]}
                                menuPortalTarget={document.body}
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        height: 35,
                                        minHeight: 35,
                                    }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >
                            <Typography
                                variant='body1'
                                gutterBottom
                                style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                            >
                                Tipping Fee
                            </Typography>
                            <CurrencyInput
                                placeholder='$0.00'
                                type='text'
                                id='TippingCost'
                                name='TippingCost'
                                value={facilityTarget.TippingCost}
                                onChange={(e) => updateForm('TippingCost', e.target.value)}
                                maskOptions={{ integerLimit: 3, allowDecimal: true, decimalSymbol: '.', decimalLimit: 4 }}
                                style={{ border: '1px solid #ccc', borderRadius: '4px' }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >
                            <Typography
                                variant='body1'
                                gutterBottom
                                style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                            >
                                Open
                            </Typography>
                            <Select
                                className='basic-single'
                                classNamePrefix='select'
                                isClearable={false}
                                isSearchable={true}
                                name='EarliestArrival'
                                value={facilityTimeOfDayOptions.find((o) => o.value == facilityTarget.EarliestArrival)}
                                onChange={(e) => updateForm('EarliestArrival', e.value)}
                                options={facilityTimeOfDayOptions}
                                defaultValue={facilityTimeOfDayOptions[0]}
                                menuPortalTarget={document.body}
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        height: 35,
                                        minHeight: 35,
                                    }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >
                            <Typography
                                variant='body1'
                                gutterBottom
                                style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                            >
                                Close
                            </Typography>
                            <Select
                                className='basic-single'
                                classNamePrefix='select'
                                isClearable={false}
                                isSearchable={true}
                                name='LatestArrival'
                                value={facilityTimeOfDayOptions.find((o) => o.value == facilityTarget.LatestArrival)}
                                onChange={(e) => updateForm('LatestArrival', e.value)}
                                options={facilityTimeOfDayOptions}
                                defaultValue={facilityTimeOfDayOptions[0]}
                                menuPortalTarget={document.body}
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        height: 35,
                                        minHeight: 35,
                                    }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                            />
                        </Grid>
                        {workspace.type === 'Roll-off' && (
                            <>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <Typography
                                        variant='body1'
                                        gutterBottom
                                        style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                                    >
                                        Material
                                    </Typography>
                                    <TextBox
                                        name='Material'
                                        maxLength={10}
                                        defaultValue={facilityTarget.Material}
                                        onValueChanged={(e) => {
                                            updateForm('Material', e.value);
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                />
                                <Grid
                                    item
                                    xs={5}
                                >
                                    <Typography
                                        variant='body1'
                                        gutterBottom
                                        style={{ height: '24px' }}
                                    />
                                    <CheckBox
                                        name='Containers'
                                        text={'Containers'}
                                        value={facilityTarget.Containers ? true : false}
                                        onValueChanged={(e) => {
                                            updateForm('Containers', e.value);
                                        }}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                )}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleEditClose('CANCEL')}
                >
                    Cancel
                </Button>
                {isFacilityFormValid && (
                    <Button
                        style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                        onClick={() => handleEditClose('SAVE')}
                    >
                        Save
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default FacilityTable;
