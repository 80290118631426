import React, { useState } from 'react';
import { RouteViewTab } from './RouteViewTab';
import { DispatchViewTab } from './DispatchViewTab';
import { ZoneViewTab } from './ZoneViewTab';
import { LayerToggle } from './LayerToggle';
import { getRouteList } from '../../_actions/MapPage.actions';
import { checkFunction } from '../../_actions/Global.actions';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

export const SidePanel = (props) => {
    const {
        siteMode,
        mapMode,
        zoneMode,
        project,
        workspace,
        serviceDay,
        bottomPaneVisibility,
        bottomPaneGrid,
        showTravelPathLabelBtnTooltip,
        alleyAssignmentMode,
        setMapMode,
        handleZoomToRoute,
        handleZoomToSelectedRoutes,
        handleZoomToScenarioRoute,
        handleZoomToSelectedScenarioRoutes,
        unSavedChanges,
        toggleMapSequenceLayer,
        travelPathLabelTooltip,
        selTravelPathRoute,
        showSequenceLabel,
        selSequenceRoute,
        selMapToolChangeHandler,
        zoomToExtent,
        toggleMapTravelPathLayer,
        setShowTravelPathLabel,
        setShowSequenceLabel,
        selDispatchTool,
        serviceDayList,
        splitFormData,
        splitSeqList,
        dispatchFacilityList,
        splitLoadIndicatorVisible,
        splitStartTime,
        defSplitFormData,
        updateSplitFormData,
        setSplitFormData,
        setSplitLoadIndicatorVisible,
        createRoutesFormData,
        routePlanList,
        createRoutesLoadIndicatorVisible,
        updateCreateRoutesFormData,
        setCreateRoutesLoadIndicatorVisible,
        scenarioList,
        selectedScenarioId,
        selectedScenarioRoutes,
        scenarioRouteList,
        showScenarioSequenceLabel,
        showScenarioTravelPathLabel,
        showScenarioReport,
        showScenarioExport,
        showScenarioTravelPathLabelBtnTooltip,
        selScenarioTravelPathRoute,
        selScenarioSequenceRoute,
        setShowScenarioTravelPathLabel,
        selScenarioMapToolChangeHandler,
        setSelectedScenarioId,
        setSelectedScenarioRoutes,
        setShowScenarioSequenceLabel,
        refreshScenarioList,
        setSelDispatchTool,
        setSplitStartTime,
        refreshSplitSeqList,
        setOpenMapAlert,
        setMapAlertTitle,
        setMapAlertMessage,
        toggleScenarioTravelPathLayer,
        toggleScenarioSequenceLayer,
        numberCreating,
        routeNumberList,
        showTravelPathLabel,
        facilityLayerVisibility,
        RAASFacilityLayerVisibility,
        streetLayerVisibility,
        trafficLayerVisibility,
        setFacilityLayerVisibility,
        setRAASFacilityLayerVisibility,
        setStreetLayerVisibility,
        setTrafficLayerVisibility,
        zoneFacilityLayerVisibility,
        zoneServiceLocationLayerVisibility,
        setZoneFacilityLayerVisibility,
        setZoneServiceLocationLayerVisibility,
        projectFacilityLayerVisibility,
        setProjectFacilityLayerVisibility,
        routePlanId,
        zoneRouteList,
        selectedZoneRoutes,
        zoneRouteSelectionChanged,
        servicePatternView,
        blockFaceLayerVisbility,
        setBlockFaceLayerVisbility,
        servicieLocationLayerVisbility,
        setServicieLocationLayerVisbility,
    } = props;

    const [disableDispatch, setDisableDispatch] = useState(true);

    React.useEffect(() => {
        getRouteList(siteMode, workspace.value, new Date().getDay(), project).then(
            (res) => {
                if (res && res.data && res.data.length === 0) {
                    if (checkFunction(214)) {
                        setDisableDispatch(true);
                        setMapMode(0);
                    }
                } else if (res && res.data && res.data.length > 0) {
                    if (checkFunction(214)) {
                        setDisableDispatch(false);
                    }
                }
            },
            () => {
                if (checkFunction(500)) {
                    setDisableDispatch(true);
                    setMapMode(0);
                }
            },
        );
    }, [workspace]);

    return (
        <div
            id='routeListDiv'
            style={{ height: mapMode === 0 ? '420px' : '390px' }}
        >
            {siteMode === 'DISPATCHER' && (
                <Tabs
                    value={mapMode}
                    onChange={(e, v) => setMapMode(v)}
                    centered
                    style={{ minHeight: '30px', height: '30px' }}
                >
                    {checkFunction(209) && (
                        <Tab
                            label='Routes'
                            value={0}
                            style={{ minHeight: '30px', fontSize: '10px', padding: 0 }}
                        />
                    )}
                    {checkFunction(214) && (
                        <Tab
                            label='Dispatch'
                            value={1}
                            disabled={disableDispatch}
                            style={{ minHeight: '30px', fontSize: '10px', padding: 0 }}
                        />
                    )}
                    {checkFunction(216) && (
                        <Tab
                            label='Zones'
                            value={2}
                            style={{ minHeight: '30px', fontSize: '10px', padding: 0 }}
                        />
                    )}
                </Tabs>
            )}
            {siteMode === 'PLANNER' && (
                <Tabs
                    value={mapMode}
                    onChange={(e, v) => setMapMode(v)}
                    centered
                    style={{ minHeight: '30px', height: '30px' }}
                >
                    {checkFunction(209) && (
                        <Tab
                            label='Routes'
                            value={0}
                            style={{ minHeight: '30px', fontSize: '10px', padding: 0 }}
                        />
                    )}
                    {checkFunction(216) && (
                        <Tab
                            label='Zones'
                            value={2}
                            style={{ minHeight: '30px', fontSize: '10px', padding: 0 }}
                        />
                    )}
                </Tabs>
            )}
            {mapMode === 0 && checkFunction(209) && (
                <RouteViewTab
                    siteMode={siteMode}
                    project={project}
                    servicePatternView={servicePatternView}
                    serviceDay={serviceDay}
                    bottomPaneVisibility={bottomPaneVisibility}
                    bottomPaneGrid={bottomPaneGrid}
                    handleZoomToRoute={handleZoomToRoute}
                    handleZoomToSelectedRoutes={handleZoomToSelectedRoutes}
                    selTravelPathRoute={selTravelPathRoute}
                    showTravelPathLabelBtnTooltip={showTravelPathLabelBtnTooltip}
                    showTravelPathLabel={showTravelPathLabel}
                    travelPathLabelTooltip={travelPathLabelTooltip}
                    showSequenceLabel={showSequenceLabel}
                    selSequenceRoute={selSequenceRoute}
                    selMapToolChangeHandler={selMapToolChangeHandler}
                    setShowTravelPathLabel={setShowTravelPathLabel}
                    setShowSequenceLabel={setShowSequenceLabel}
                    toggleMapTravelPathLayer={toggleMapTravelPathLayer}
                    toggleMapSequenceLayer={toggleMapSequenceLayer}
                    refreshRouteList={props.refreshRouteList}
                    workspace={workspace}
                    routeViewTabCategorization={props.routeViewTabCategorization}
                    handleRouteTabViewCategorySelection={props.handleRouteTabViewCategorySelection}
                    routeViewTableData={props.routeViewTableData}
                    handleToggleUpdate={props.handleToggleUpdate}
                    isRouteViewTabLoading={props.isRouteViewTabLoading}
                    alleyAssignmentMode={alleyAssignmentMode}
                />
            )}
            {mapMode === 1 && checkFunction(214) && (
                <DispatchViewTab
                    workspace={workspace}
                    selDispatchTool={selDispatchTool}
                    serviceDayList={serviceDayList}
                    splitFormData={splitFormData}
                    splitSeqList={splitSeqList}
                    dispatchFacilityList={dispatchFacilityList}
                    splitLoadIndicatorVisible={splitLoadIndicatorVisible}
                    splitStartTime={splitStartTime}
                    defSplitFormData={defSplitFormData}
                    createRoutesFormData={createRoutesFormData}
                    routePlanList={routePlanList}
                    createRoutesLoadIndicatorVisible={createRoutesLoadIndicatorVisible}
                    handleZoomToScenarioRoute={handleZoomToScenarioRoute}
                    handleZoomToSelectedScenarioRoutes={handleZoomToSelectedScenarioRoutes}
                    scenarioList={scenarioList}
                    selectedScenarioId={selectedScenarioId}
                    selectedScenarioRoutes={selectedScenarioRoutes}
                    scenarioRouteList={scenarioRouteList}
                    showScenarioSequenceLabel={showScenarioSequenceLabel}
                    showScenarioTravelPathLabel={showScenarioTravelPathLabel}
                    bottomPaneGrid={bottomPaneGrid}
                    bottomPaneVisibility={bottomPaneVisibility}
                    showScenarioReport={showScenarioReport}
                    showScenarioExport={showScenarioExport}
                    showScenarioTravelPathLabelBtnTooltip={showScenarioTravelPathLabelBtnTooltip}
                    travelPathLabelTooltip={travelPathLabelTooltip}
                    selScenarioTravelPathRoute={selScenarioTravelPathRoute}
                    selScenarioSequenceRoute={selScenarioSequenceRoute}
                    routeNumberList={routeNumberList}
                    numberCreating={numberCreating}
                    setShowScenarioTravelPathLabel={setShowScenarioTravelPathLabel}
                    selScenarioMapToolChangeHandler={selScenarioMapToolChangeHandler}
                    setSelectedScenarioId={setSelectedScenarioId}
                    setSelectedScenarioRoutes={setSelectedScenarioRoutes}
                    setShowScenarioSequenceLabel={setShowScenarioSequenceLabel}
                    zoomToExtent={zoomToExtent}
                    updateCreateRoutesFormData={updateCreateRoutesFormData}
                    setCreateRoutesLoadIndicatorVisible={setCreateRoutesLoadIndicatorVisible}
                    updateSplitFormData={updateSplitFormData}
                    setSplitFormData={setSplitFormData}
                    setSplitLoadIndicatorVisible={setSplitLoadIndicatorVisible}
                    refreshScenarioList={refreshScenarioList}
                    setSelDispatchTool={setSelDispatchTool}
                    setSplitStartTime={setSplitStartTime}
                    refreshSplitSeqList={refreshSplitSeqList}
                    setOpenMapAlert={setOpenMapAlert}
                    setMapAlertTitle={setMapAlertTitle}
                    setMapAlertMessage={setMapAlertMessage}
                    toggleScenarioTravelPathLayer={toggleScenarioTravelPathLayer}
                    toggleScenarioSequenceLayer={toggleScenarioSequenceLayer}
                />
            )}
            {mapMode === 2 && checkFunction(216) && (
                <ZoneViewTab
                    zoneMode={zoneMode}
                    serviceDay={serviceDay}
                    routePlanId={routePlanId}
                    zoneRouteList={zoneRouteList}
                    selectedZoneRoutes={selectedZoneRoutes}
                    unSavedChanges={unSavedChanges}
                    zoneRouteSelectionChanged={zoneRouteSelectionChanged}
                />
            )}
            <LayerToggle
                siteMode={siteMode}
                mapMode={mapMode}
                workspace={workspace}
                selDispatchTool={selDispatchTool}
                facilityLayerVisibility={facilityLayerVisibility}
                RAASFacilityLayerVisibility={RAASFacilityLayerVisibility}
                streetLayerVisibility={streetLayerVisibility}
                trafficLayerVisibility={trafficLayerVisibility}
                zoneFacilityLayerVisibility={zoneFacilityLayerVisibility}
                zoneServiceLocationLayerVisibility={zoneServiceLocationLayerVisibility}
                projectFacilityLayerVisibility={projectFacilityLayerVisibility}
                setZoneFacilityLayerVisibility={setZoneFacilityLayerVisibility}
                setZoneServiceLocationLayerVisibility={setZoneServiceLocationLayerVisibility}
                setFacilityLayerVisibility={setFacilityLayerVisibility}
                setRAASFacilityLayerVisibility={setRAASFacilityLayerVisibility}
                setStreetLayerVisibility={setStreetLayerVisibility}
                setTrafficLayerVisibility={setTrafficLayerVisibility}
                setProjectFacilityLayerVisibility={setProjectFacilityLayerVisibility}
                blockFaceLayerVisbility={blockFaceLayerVisbility}
                setBlockFaceLayerVisbility={setBlockFaceLayerVisbility}
                servicieLocationLayerVisbility={servicieLocationLayerVisbility}
                setServicieLocationLayerVisbility={setServicieLocationLayerVisbility}
            />
        </div>
    );
};
